<template>
  <div class="systemStatusManage">
    <!-- 顶部按钮组 -->
    <!-- 顶部按钮组 -->
    <div class="topView">
      <div class="headLeft">
        <div style="margin-right: 10px; margin-left: 14px; padding-bottom: 20px;">
          <img @click="addIdentity('add')" src="@/assets/image/addBtn.png" />
        </div>
      </div>  
    </div>
    <div class="pageTitle">系统身份管理</div>
     <div
      style="
        background: #fff;
        border-radius: 10px;
        padding: 16px;
        box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.1);
      "
    >
    <el-table   :header-cell-style="{color: '#666', background: '#f0f0f0'}" :data="tableData" height="600px">
      
      <el-table-column align="center" type="index" prop="UserID" label="序号"  width="50">
      </el-table-column>
      <el-table-column align="center" prop="Name" label="身份名称">
      </el-table-column>
      <el-table-column align="center" prop="Remark" label="身份描述">
      </el-table-column>
      <el-table-column align="center" prop="ShowType" label="显示类型">
      </el-table-column>
      <el-table-column align="center" prop="status" label="身份使用状态">
      </el-table-column>

      <el-table-column align="center"  label="操作" width="300">
          <template slot-scope="scope">
            <el-button type="primary" size="small" @click="giveAuth(scope.row)">分配权限</el-button>
            <el-button type="primary" size="small" @click="addIdentity('edit', scope.row)">编辑</el-button>
            <el-button type="success" size="small" @click="confirmSubmit(scope.row)">权限应用</el-button>
          </template>
      </el-table-column>
    </el-table>
     </div>
    <!--添加编辑身份-->
    <div class="addPerson">
     <el-dialog :title="type=='add'?'添加身份':'编辑身份'"  :visible.sync="showIdentityInfo" >
    
      <el-form :model="IdentityInfo"  label-width="106px"  ref="IdentityInfo" :rules="addrules" >

       <div class="infos">
          <el-form-item label="身份名称" prop="name" >
            <el-input  style="width: 220px" v-model="IdentityInfo.name" auto-complete="off" clearable placeholder='请输入角色名称'>
            </el-input>
          </el-form-item>
       </div>
       <div class="infos">
          <el-form-item label="使用状态" prop="state" >
           <el-select style="width: 220px" v-model="IdentityInfo.state" placeholder="请选择使用状态">
            <el-option 
            
            v-for="item in stateOption"
            :key="item.value"
            :label="item.label"
            :value="item.value">
            </el-option>
        </el-select> 
          </el-form-item>
          </div>
       <div class="infos">
          <el-form-item label="身份类型" prop="type" >
             <el-select v-model="IdentityInfo.type" placeholder="请选择身份类型"  style="width: 220px">
            <el-option 
            v-for="item in identityOption"
            :key="item.value"
            :label="item.label"
            :value="item.value">
            </el-option>
        </el-select>
          </el-form-item>
          </div>
          <div class="info">
            <div class="name">身份描述</div>
            <el-input
              style="width: 314px"
              v-model="IdentityInfo.desc"
              auto-complete="off"
              clearable
              placeholder="请输入备注"
              type="textarea"
            >
            </el-input>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" v-show="type=='add'" @click="addTable('IdentityInfo')">确认添加</el-button>
        <el-button type="primary" v-show="type=='edit'" @click="onSumbitEdit('IdentityInfo')">确定修改</el-button>
        <el-button @click="showIdentityInfo = false">取 消</el-button>
      </div>
    </el-dialog>
    </div>
  
     <!--分配权限弹框-->
    <el-dialog title="分配权限" width="80%" :visible.sync="giveAuthShow">
      <div class="roleInfo">
        <div>身份名称:{{roleData.Name}}</div>
        <!--<div>角色归属: 市场</div>-->
        <div style="display:flex;">
          <div>身份描述:{{roleData.Remark}}</div>
          
        </div>
        <div>显示类型: {{roleData.ShowType}}</div>
        <div>身份使用状态:<span :style="roleData.Enabled ? 'color: #13D298' : 'color: #FF2323'">{{roleData.status}}</span></div>
      </div>
      <div class="cas">
      
        <div class="tree">
          <div class="title-box">
            <div class="title">角色拥有权限</div>
            <!-- <div>{{selectNum}}/{{totalNum}}</div> -->
          </div>
          <el-tree class="inner" :data="authOptions" show-checkbox node-key="ID" :check-strictly="true" default-expand-all ref="tree" :default-checked-keys="showValue" @check="nodeCheck" :props="defaultProps">
          </el-tree>
        </div>
        <div class="dialog-btn">
          <div @click="onSure" class="btn" style="margin-bottom: 32px;">确定</div>
          <div @click="giveAuthShow = false" class="btn">返回</div>
        </div>
      </div>
   
    </el-dialog>
  </div>
</template>

<script>


import {
  getSelectMenuId
} from "@/api/power/role";
import { getSysList, addIdentity, editIdentity, updateIdentity, getUserIdentity, giveIdentityMenus, getAllMenus} from '@/api/power/system'
export default {
  data() {
    return {   
     
        casValue: [
         
        ], // 选择角色可用的菜单
        stateOption: [{
            value: true,
            label: '启用'
            }, {
            value: false,
            label: '禁用'
            }],
        identityOption: [{
            value: 1,
            label: 'PC端'
            }, {
            value: 0,
            label: '平台'
            }, {
            value: 2,
            label: '其他端'
            }],    
      IdentityInfo: {
          name: '',
          state: '',
          type: '',
          desc: ''

      }, // 人员信息
      // 添加表单验证
       addrules: {
          name: [
            { required: true, message: '请输入员工姓名', trigger: 'blur' },
          ],
         
          state: [
            {  required: true,  message: '请选择使用状态', trigger: 'change' }
          ],
          type: [
            {  required: true,  message: '请输入身份类型', trigger: 'change' }
          ],
          desc: [
            { message: '请填写角色描述', trigger: 'blur' }
          ]
        },
      showIdentityInfo: false, // 显示添加编辑角色弹窗
      giveAuthShow: false, // 赋予角色权限
      type: 'add', // add 为添加，edit为编辑
      editInfo: {
        ID: ''
      }, // 编辑的信息
    
      roleSelectionList: [], // 选择了的角色列表
      tableData: [], //表格数据
      roleData: {},  // 身份信息
      defaultProps: {
        children: "children",
        value: "ViewPowerID",
        label: "Name",
      }, // tree 配置
      showValue: [], // 用于回显的角色菜单
        authOptions: []
    }
  },
  methods: {
   
    // 添加角色
    addTable(IdentityInfo) {
        this.$refs[IdentityInfo].validate((valid) => {
          if (valid) {
            let params = {
              name: this.IdentityInfo.name,
              Enable: this.IdentityInfo.state,    
              remark: this.IdentityInfo.desc,
              showType: this.IdentityInfo.type
            }
            addIdentity(params).then(res => {
              this.showIdentityInfo = false;
              this.$refs[IdentityInfo].resetFields()
              this.$message({
                message: '添加成功',
                type: 'success'
              });
              
              getSysList().then(res => {
              res.data.forEach(element => {
                if(element.Enabled) {
                  element.status = '启用'
                } else {
                  element.status = '禁用'
                }
              });
              this.tableData = res.data
            })  
                
            })
          } else {
            this.$message.error('请填写正确的信息');
            return false;
          }
        });
      },
    // 修改人员
    onSumbitEdit(IdentityInfo){
      this.$refs[IdentityInfo].validate((valid) => {
          if (valid) {
            let params = {
              Id: this.editInfo.ID,
              name: this.IdentityInfo.name,
              Enable: this.IdentityInfo.state,    
              remark: this.IdentityInfo.desc,
              showType: this.IdentityInfo.type
            }
            editIdentity(params).then(res => {

              this.showIdentityInfo = false;
              this.$refs[IdentityInfo].resetFields()
              this.$message({
                message: '修改成功',
                type: 'success'
              });
              this.getSysList();
            })
          } 
        });
    },
    // 赋予角色
    onSure() {
      let params = {
        Id: this.roleData.ID,
        powerJson: this.casValue
      }
      giveIdentityMenus(params).then(res => {
              this.$message({
                message: '分配权限成功',
                type: 'success'
            });
            this.giveAuthShow = false
      }) 
    },

    //新增角色
     addIdentity(scope, row) {
      this.showIdentityInfo = true;
      if (scope == 'add') {
        this.title = 'add';  
        this.type = 'add'
        this.$refs['IdentityInfo'].resetFields()
         this.IdentityInfo.name = ''
        this.IdentityInfo.state = ''
        this.IdentityInfo.desc = ''
      }
      if (scope == 'edit') {
        this.type = 'edit'
        // 数据回显
        this.editInfo = row
        this.IdentityInfo.name = row.Name
        this.IdentityInfo.state = row.Enabled
        this.IdentityInfo.desc = row.Remark
        this.IdentityInfo.type = row.ShowType
      }
    },
    // 赋予角色弹框
    giveAuth(row) {
      this.giveAuthShow = true
      this.roleData = row
      this.casValue = []
      this.showValue = []
      // 传id 获取该角色已经获取的权限 回显
        let params = {
          Id: row.ID,
        }
        getUserIdentity(params).then(res => {
          if (res.data.DefaultPowers == null) {
          let arr = res.data[0];
          this.casValue = [arr.ID];
          this.$refs.tree.setCheckedKeys(this.showValue);
        } else {
          let arr = {
            powerJson: res.data.DefaultPowers
          }
          this.casValue = res.data.DefaultPowers.split(',')

          getSelectMenuId(arr).then((res) => {
            this.showValue = res.data;
             this.$refs.tree.setCheckedKeys(this.showValue);
          });
        }
      })
    },
   
    // 获取角色列表
    getSysList() {
      getSysList().then(res => {
        res.data.forEach(element => {
                if(element.Enabled) {
                  element.status = '启用'
                } else {
                  element.status = '禁用'
                }
              });
        this.tableData = res.data
      })
    },
    // 验证是否赋予权限
    confirmSubmit(row) {
        this.$confirm('是否确认应用权限？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let params = {
            identityId: row.ID
          }
          updateIdentity(params).then(res => {
            this.$message({
                    message: '权限应用成功',
                    type: 'success'
                  }); 
           })
         
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消应用'
          });          
        });
      },
    // 子菜单关联上级菜单
    nodeCheck(currentNode, treeStatus) {
      const setChildStatus = (node, status) => {
        /* 这里的 id children 也可以是其它字段，根据实际的业务更改 */
        this.$refs.tree.setChecked(node.ID, status);
        if (node.children) {
          /* 循环递归处理子节点 */
          for (let i = 0; i < node.children.length; i++) {
            setChildStatus(node.children[i], status);
          }
        }
      };
      /* 设置父节点为选中状态 */
      const setParentStatus = (nodeObj) => {
        /* 拿到tree组件中的node,使用该方法的原因是第一次传入的 node 没有 parent */
        const node = this.$refs.tree.getNode(nodeObj);
        if (node.parent.key) {
          this.$refs.tree.setChecked(node.parent, true);
          setParentStatus(node.parent);
        }
      };
      /* 判断当前点击是选中还是取消选中操作 */
      if (treeStatus.checkedKeys.includes(currentNode.ID)) {
        setParentStatus(currentNode);
        setChildStatus(currentNode, true);
      } else {
        /* 取消选中 */
        if (currentNode.children) {
          setChildStatus(currentNode, false);
        }
      }
      // 获取到所有选中的节点，拿到ViewPowerID
      let roles = [...this.$refs.tree.getCheckedNodes()];
      let arr = []
      roles.forEach((item) => {
        arr.push(item.ViewPowerID)
      })
      let menuArr = this.unique(arr); // 去除重复的节点
      this.casValue = menuArr
    },
    // 数组去重
    unique(arr) {
      let newArr = []
      let len = arr.length
      for (let i = 0; i < len; i++) {
        if (newArr.indexOf(arr[i]) == -1) {
          newArr.push(arr[i])
        }
      }
      return newArr
    },
    // 获取所有权限列表 传0为获取全部
    getMenu() {
      // 获取所有菜单列表 传0
      getAllMenus().then(res => {
        this.authOptions = res.data
      })
    }
  },
  created() {
   this.getSysList();
   this.getMenu();
  }
}
</script>
<style lang="scss">
.systemStatusManage {
  .addPerson {
  .el-dialog__body {
    display: flex;
    justify-content: center;
  
  }
  }
.infos {
  border-bottom: 1px solid #f5f5f5;
  margin-bottom: 16px;
  width: 320px;
  display: flex;
  justify-content: center;
  .el-form-item {
    margin-bottom: 0;
  }
  .el-form-item__label {
    text-align: left;
    color: #333333;
  }
  .el-form-item__error {
    left: 14px !important;
  }
  .el-input__inner {
    border: none;
  }
}
.info {
  margin-bottom: 16px;
  width: 320px;
  font-size: 14px;
  padding-left: 6px;
  .el-form-item {
    margin-bottom: 0;
  }
  .el-textarea__inner {
    height: 80px;
  }
  .name {
    margin-bottom: 8px;
    font-size: 14px;
    color: #333333;
  }
  .el-input__inner {
    border: none;
  }
}
}
</style>
<style lang="scss" scoped>
@import '../../../../assets/style/variable.scss';
.systemStatusManage {
  .head {
    border-bottom: 1px solid $borderGray;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }
  .btns {
    margin-bottom: 20px;
  }
  .cas {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;
  }
  .searchForm {
    display: flex;
    flex-wrap: wrap;
  }
  .addForm {
    display: flex;
    flex-wrap: wrap;
    .el-item {
      width: 50%;
    }
    .el-date-editor {
      width: 100% !important;
    }
  }
  .pagination {
    margin-top: 10px;
  }
  .addDialog {
    .body {
      .el-autocomplete {
        width: 100%;
      }
      .choose {
        margin-top: 50px;
        display: flex;
        justify-content: center;
      }
    }
  }
  .dialog-btn {
    margin-left: 16%;
    .btn {
      background: $primary;
      box-shadow: 0px 3px 6px rgba(32, 129, 255, 0.3);
      color: #fff;
      font-weight: 400;
      padding: 5px 24px;
      border-radius: 4px;
      cursor: pointer;
    }
  }
  .roleInfo {
    display: flex;
    border-bottom: 1px solid #f5f5f5;
    justify-content: space-between;
    padding-bottom: 24px;
    margin-bottom: 24px;
    font-size: 14px;
    font-weight: 400;
    color: #333333;
  }
  .cas {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;
    .tree {
      background: #ffffff;
      border-radius: 12px;
      display: inline-block;
      box-shadow: -1px 4px 20px rgba(0, 0, 0, 0.13);
      padding: 24px 0 32px 0;
      height: 400px;
      overflow-y: auto;
      .inner {
        margin-right: 170px;
        margin-left: 24px;
      }
      .title-box {
        color: #999999;
        margin-bottom: 16px;
        padding: 0 24px 8px;
        border-bottom: 1px solid #f5f5f5;
        display: flex;
        justify-content: space-between;
        font-size: 16px;
      }
    }
  }
}
</style>